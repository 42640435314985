/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

const Form = ({ children, handleSubmit, onSubmit }) => (
  <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
);

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
};

const FormRedux = reduxForm({
  form: 'applyform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form);

export default FormRedux;
