/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';

const MainContainer = ({ children }) => (
  <div className='container main-container'>{children}</div>
);

MainContainer.propTypes = {
  children: PropTypes.node,
};

export default MainContainer;
