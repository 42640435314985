import React, { PureComponent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

type Props = {
  hideSaveStateNotification: Function;
};

class SaveStateToastSuccess extends PureComponent<Props, {}> {
  render() {
    const {
      hideSaveStateNotification: hideSaveStateNotificationAction,
    } = this.props;

    return (
      <div className='notificationContainer'>
        <M.ToastNotification
          kind='success'
          title={i18n.getStr(
            'i18nInternational.stateToastSuccess.youSavedApplication',
          )}
          notificationType='toast'
          className='saveStateSuccessNotification'
          timeout={5000}
          onCloseButtonClick={hideSaveStateNotificationAction}
          caption={i18n.getStr(
            'i18nInternational.stateToastSuccess.youCanResume',
          )}
          iconDescription={i18n.getStr('i18nInternational.closeNotification')}
        />
      </div>
    );
  }
}

export default i18n.renderTranslation()(SaveStateToastSuccess);
