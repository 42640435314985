import React, { PureComponent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

type Props = {
  hideSaveStateFailureNotification: Function;
  saveStateSubmitErrors: string;
};

class SaveStateToastFailure extends PureComponent<Props, {}> {
  render() {
    const {
      hideSaveStateFailureNotification: hideSaveStateFailureNotificationAction,
      saveStateSubmitErrors: saveStateSubmitErrorsState,
    } = this.props;

    return (
      <div className='notificationContainer'>
        <M.ToastNotification
          kind='error'
          title={i18n.getStr('errors.failureSavingYourApplication')}
          notificationType='toast'
          className='saveStateFailureNotification'
          timeout={5000}
          onCloseButtonClick={hideSaveStateFailureNotificationAction}
          caption={saveStateSubmitErrorsState}
          iconDescription={i18n.getStr('i18nInternational.closeNotification')}
        />
      </div>
    );
  }
}

export default SaveStateToastFailure;
