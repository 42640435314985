import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  hideSaveStateNotification,
  hideSaveStateFailureNotification,
} from '../../actions';
import { handleIntlFormSaveState } from '../../actions/submission';
import SaveStateToastSuccess from './SaveStateToastSuccess';
import SaveStateToastFailure from './SaveStateToastFailure';

type Props = {
  hideSaveStateButton?: boolean;
  lastPage?: boolean;
  canGoBack?: boolean;
  onPrev: () => void;
  isSubmitting?: boolean;
  form?: any;
  handleIntlFormSaveState: Function;
  hideSaveStateNotification: Function;
  hideSaveStateFailureNotification: Function;
  showSaveStateNotification: boolean;
  saveStateSubmitErrors: string;
  postSubmissionActionPresent: boolean;
};
class Navigation extends PureComponent<Props, {}> {
  floatRight: React.CSSProperties = { float: 'right' };

  canSaveState() {
    const { form } = this.props;
    const requiredSaveStateFields = [
      'citizenship',
      'citizenship_identity.name',
      'citizenship_identity.value',
      'dob',
      'email',
    ];
    return requiredSaveStateFields.every(field => get(form, field));
  }

  submitButtonText() {
    const { lastPage, postSubmissionActionPresent } = this.props;
    let i18name;
    if (lastPage && postSubmissionActionPresent) {
      i18name = 'i18nInternational.navigation.submitAndContinue';
    } else if (lastPage) {
      i18name = 'i18nInternational.navigation.submit';
    } else {
      i18name = 'i18nInternational.navigation.continue';
    }
    return i18n.getStr(i18name);
  }

  render() {
    const {
      hideSaveStateButton = false,
      canGoBack = true,
      onPrev,
      isSubmitting,
      handleIntlFormSaveState: saveStateAction,
      hideSaveStateNotification: hideSaveStateNotificationAction,
      hideSaveStateFailureNotification: hideSaveStateFailureNotificationAction,
      showSaveStateNotification: showSaveStateNotificationState,
      saveStateSubmitErrors: saveStateSubmitErrorsState,
    } = this.props;

    const prevPage = (e: Event) => {
      onPrev();
    };

    return (
      <>
        {showSaveStateNotificationState && (
          <SaveStateToastSuccess
            hideSaveStateNotification={hideSaveStateNotificationAction}
          />
        )}
        {saveStateSubmitErrorsState && (
          <SaveStateToastFailure
            hideSaveStateFailureNotification={
              hideSaveStateFailureNotificationAction
            }
            saveStateSubmitErrors={saveStateSubmitErrorsState}
          />
        )}
        <M.GridRow>
          {canGoBack && (
            <M.GridCol style={{ flexBasis: '33%' }}>
              <M.Button kind='secondary' onClick={prevPage}>
                <i className='fa fa-angle-left' />
                &nbsp;{i18n.getStr('i18nInternational.navigation.back')}
              </M.Button>
            </M.GridCol>
          )}
          <M.GridCol style={{ flexBasis: '67%' }}>
            <div style={this.floatRight}>
              {isSubmitting ? (
                <>
                  <M.LoadingInline />
                </>
              ) : (
                <>
                  {hideSaveStateButton || (
                    <M.Button
                      onClick={saveStateAction}
                      kind='secondary'
                      disabled={!this.canSaveState()}
                    >
                      {i18n.getStr('i18nInternational.navigation.save')}
                    </M.Button>
                  )}
                  <M.Button type='submit'>{this.submitButtonText()}</M.Button>
                </>
              )}
            </div>
          </M.GridCol>
        </M.GridRow>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state?.form?.intlForm?.values,
  showSaveStateNotification: state?.navigation.showSaveStateNotification,
  saveStateSubmitErrors: state?.navigation.saveStateSubmitErrors,
});
const mapDispatchToProps = {
  hideSaveStateNotification,
  hideSaveStateFailureNotification,
  handleIntlFormSaveState,
};

export default i18n.renderTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Navigation),
);
