import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  capitalize,
  each,
  isArray,
  isNumber,
  isObject,
  isString,
  without,
} from 'lodash';
import { getLocalizedGlobalError } from 'lib/helpers';

const GlobalErrors = ({ errors, isInternational }) =>
  !!errors &&
  !!errors.length && (
    <div className='global-errors'>
      {errors.map((errorMessage, i) => (
        <div key={`error-${i + 1}`} className='alert alert-danger' role='alert'>
          {isInternational
            ? getLocalizedGlobalError(errorMessage)
            : errorMessage}
        </div>
      ))}
    </div>
  );

GlobalErrors.propTypes = {
  errors: PropTypes.array,
};

const fullMessage = (keys, error) => {
  let message = `${capitalize(without(keys, 'base').join(' '))} ${error}`;

  // If the first 2 words are identical, drop one of them
  // Intended to fix error messages that are "Candidate Candidate must be ..."
  // eslint-disable-next-line prefer-const
  let brokenMessage = message.split(' ');
  if (brokenMessage[0] === brokenMessage[1]) {
    brokenMessage.shift();
    message = brokenMessage.join(' ');
  }

  return message;
};

// Read errors by key up to N levels deep
export const fullErrorMessages = (errors, cursor = [], errorMessages = []) => {
  const buildErrorsOrTraverse = (value, key) => {
    if (isArray(value)) {
      value.forEach(errorMessage =>
        errorMessages.push(fullMessage(cursor, errorMessage)),
      );
    } else if (isObject(value)) {
      fullErrorMessages(value, cursor, errorMessages);
    } else if (isString(value)) {
      let errorMessage = value;
      if (!isNumber(key)) {
        errorMessage = `${value}: ${key}`;
      }
      errorMessages.push(errorMessage);
    }
  };

  each(errors, (value, key) => {
    cursor.push(key);
    buildErrorsOrTraverse(value, key);
    cursor.pop();
  });

  return errorMessages;
};

const mapStateToProps = ({
  form: {
    applyform: { submitErrors },
  },
}) => {
  return {
    errors: fullErrorMessages(submitErrors),
  };
};

export const IntlGlobalErrors = connect(
  ({
    configuration: {
      configuration: { format },
    },
    form: {
      intlForm: { submitErrors, candidateTaskErrors },
    },
  }) => {
    const errors = fullErrorMessages(submitErrors || candidateTaskErrors);
    return {
      errors,
      isInternational: format === 'international',
    };
  },
)(GlobalErrors);

export default connect(mapStateToProps)(GlobalErrors);
