import PropTypes from 'prop-types';
import React from 'react';

import { CustomComponentList } from './CustomComponent';

const Header = ({ components }) =>
  !!components &&
  !!components.length && (
    <header className='header'>
      <CustomComponentList list={components} />
    </header>
  );

Header.propTypes = {
  components: PropTypes.array,
};

export default Header;
