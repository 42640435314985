import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--dark-gray-20, #dfdfe0);
  background: var(--white, #fff);
`;

const StyledHR = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid #e4e7ed;
  width: 100%;
  margin: 0;
`;

interface ContainerProps {
  divider1?: boolean;
  divider2?: boolean;
}

const SectionContainer: React.FC<ContainerProps> = ({
  children,
  divider1 = false,
  divider2 = false,
}) => {
  const [child1, child2, child3] = React.Children.toArray(children);

  return (
    <StyledContainer>
      {child1 && child1}
      {divider1 && child2 && <StyledHR />}
      {child2 && child2}
      {divider2 && child3 && <StyledHR />}
      {child3 && child3}
    </StyledContainer>
  );
};

export default SectionContainer;
