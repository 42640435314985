import React from 'react';
import PropTypes from 'prop-types';

import { CustomComponentList } from './CustomComponent';
import { Navigation } from './Navigation';
import GlobalErrors from './GlobalErrors';
import NavigationForm from './NavigationForm';

const CustomComponentSection = ({ list }) => (
  <CustomComponentList list={list} />
);

CustomComponentSection.propTypes = {
  list: PropTypes.array.isRequired,
};

const NavigationSection = ({ navigationAttributes }) => {
  return (
    navigationAttributes.showNav && <Navigation {...navigationAttributes} />
  );
};

NavigationSection.propTypes = {
  navigationAttributes: PropTypes.shape({
    showNav: PropTypes.bool.isRequired,
  }).isRequired,
};

const Page = ({ navigation, page: { components } }) => (
  <div className='page'>
    <NavigationForm navigation={navigation}>
      <CustomComponentSection list={components} />
      <GlobalErrors />
    </NavigationForm>
  </div>
);

Page.propTypes = {
  navigation: PropTypes.object.isRequired,
  page: PropTypes.shape({
    components: PropTypes.array.isRequired,
  }).isRequired,
};

export default Page;
