import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import '../css/steps.scss';
import WELCOME_PAGE from 'identifiers/identifiers';

const Step = ({ className, name }) => (
  <li className={className}>
    <T value={`pages.${name}`} />
  </li>
);

Step.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const Steps = ({ steps, currentStep }) => {
  return (
    <ul
      data-testid={WELCOME_PAGE.progressBar}
      className='cd-multi-steps text-bottom count'
    >
      {steps.map((step, i) => {
        let stepClass = '';

        if (i < currentStep) {
          stepClass = 'visited';
        }

        if (i === currentStep) {
          stepClass = 'current';
        }

        return <Step key={`step-${i + 1}`} name={step} className={stepClass} />;
      })}
    </ul>
  );
};

Steps.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default Steps;
