import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const AccordianContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const AccordionWrapper = styled.div`
  display: flex;
  align-items: start;
  padding: 0;
  margin-top: 9px;
  width: 100%;
`;

const AccordionTextWrapper = styled.div<{ paddingBottom: boolean }>`
  color: #001730;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: ${props => (props.paddingBottom ? '16px' : '0')};
`;

const StyledLine = styled.div<{ widthPercent: number }>`
  border-top: 1px solid #f2f2f2; // choose your preferred color
  width: ${props => `${props.widthPercent}%`};
  @media (max-width: 768px) {
    width: ${props => `${props.widthPercent - 5}%`};
  }
`;

const IconWrapper = styled.div`
  position: relative;
  top: -6px;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
`;

const RequirementsHeader = styled.h3`
  color: #576c82;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding-top: 8px;
`;

const RequirementsUl = styled.ul<{ paddingBottom: boolean }>`
  list-style: none; /* This removes the default bullet style */
  padding-left: 20px; /* This reduces the default left indent */
  padding-bottom: ${props => (props.paddingBottom ? '16px' : '0')};
`;
const StyledTitle = styled.h3`
  font-size: 14px;
  font-weight: 'normal';
`;

const RequirementsListItem = styled.li`
  color: #001730;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  list-style: disc;

  &:before {
    content: '•';
    color: #000;
    display: inline-block; /* This makes the filled circle sit on the same line */
    width: 1em; /* This provides some spacing between the filled circle and the list item text */
    margin-left: -1em; /* This provides some spacing between the filled circle and the list item text */
  }
`;

type RequirementsProps = {
  header: string;
  requiredDocs: any;
};

const Requirements = ({ header, requiredDocs }: RequirementsProps) => {
  if (requiredDocs === false) {
    return null;
  }

  return (
    <>
      <RequirementsHeader>{header}</RequirementsHeader>
      <RequirementsUl paddingBottom={!!requiredDocs}>
        {requiredDocs.map((requiredDoc: string) => (
          <RequirementsListItem key={requiredDoc}>
            {requiredDoc}
          </RequirementsListItem>
        ))}
      </RequirementsUl>
    </>
  );
};

const Accordion = ({
  title,
  text,
  requirementHeader = 'ACCEPTABLE DOCUMENTS',
  requiredDocs = [],
  boldTitle = true,
  accordionDisabled = false,
  open = false,
  align = 'end',
  showIcon = true,
}: {
  title: string;
  text: React.ReactNode;
  requirementHeader?: string;
  requiredDocs?: string[] | boolean;
  boldTitle?: boolean;
  accordionDisabled?: boolean;
  open?: boolean;
  align?: 'start' | 'end';
  showIcon?: boolean;
}) => {
  const requirementsComponent = requiredDocs && (
    <Requirements header={requirementHeader} requiredDocs={requiredDocs} />
  );

  return (
    <AccordianContainer>
      <StyledLine widthPercent={93.5} />
      <AccordionWrapper>
        {showIcon && (
          <IconWrapper>
            <M.Icon icon='CheckmarkOutline' size={20} color='#047591' />
          </IconWrapper>
        )}
        <M.Accordion
          align={align}
          dropdown
          disabled={accordionDisabled}
          style={{ width: '100%', paddingBottom: '0 !important' }}
          className='down'
        >
          <M.AccordionItem
            title={<StyledTitle>{title}</StyledTitle>}
            open={open}
            style={{
              borderBottom: 'none',
              width: '100%',
              paddingBottom: '0 !important',
            }}
          >
            <AccordionTextWrapper paddingBottom={!requiredDocs}>
              {text}
            </AccordionTextWrapper>
            {/* IF REQUIREMENTS, DISPLAY LISTED REQUIREMENTS */}
            {requirementsComponent}
          </M.AccordionItem>
        </M.Accordion>
      </AccordionWrapper>
    </AccordianContainer>
  );
};

export default Accordion;
