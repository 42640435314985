import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import React, { PureComponent } from 'react';
import { Form, FormSubmitHandler, reduxForm } from 'redux-form';
import Navigation from './Navigation';

type Props = {
  handleSubmit: (a: Function) => FormSubmitHandler;
  onSubmit: (e: Event) => void;
  isSubmitting: boolean;
};

class IdentityVerificationRedirect extends PureComponent<Props, {}> {
  render() {
    const { handleSubmit, onSubmit, isSubmitting } = this.props;

    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <M.Container>
            <M.Container.Row>
              <M.Container.Col>
                <h1>
                  {i18n.getStr(
                    'i18nInternational.identityVerification.identityVerification',
                  )}
                </h1>
                <p>
                  {i18n.getStr(
                    'i18nInternational.identityVerification.redirectedToVendor',
                  )}
                </p>
                <p>
                  {i18n.getStr(
                    'i18nInternational.identityVerification.pleaseHaveApprovedID',
                  )}
                </p>
              </M.Container.Col>
            </M.Container.Row>
          </M.Container>
          <Navigation
            hideSaveStateButton
            canGoBack={false}
            onPrev={() => {}}
            lastPage={false}
            isSubmitting={isSubmitting}
          />
        </Form>
      </>
    );
  }
}

export default i18n.renderTranslation()(
  reduxForm({
    form: 'intlForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(IdentityVerificationRedirect as any),
);
