import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  getEventNameForDomesticPageViewed,
  ANALYTICS_PROPERTIES,
} from '../lib/analytics';

const ErrorPage = ({ error, trackAnalyticsEvent }) => {
  useEffect(() => {
    trackAnalyticsEvent(getEventNameForDomesticPageViewed('Error'), {
      [ANALYTICS_PROPERTIES.ERROR_MESSAGE]: error.message,
      [ANALYTICS_PROPERTIES.ERROR_CODE]: error.status,
    })({}, () => {});
  }, [trackAnalyticsEvent, error]);

  return (
    <div className='alert alert-warning text-center p-4 h4'>
      {error.message}
    </div>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object.isRequired,
  trackAnalyticsEvent: PropTypes.func.isRequired,
};

export default ErrorPage;
