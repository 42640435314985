import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Translate as T } from 'react-redux-i18n';
import WELCOME_PAGE from 'identifiers/identifiers';

const StyledTitleWrapper = styled.p`
  color: var(
    --text-icon-light-background-primary,
    var(--text-icon-black-primary, #1a2026)
  );

  /* Heading 1 */
  font-family: Helvetica Neue;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 24px */
  margin-bottom: 14px;
  padding: 10px 0px;

  @media (max-width: 768px) {
    padding: 2px 0px 10px 0px;
  }
`;

export const StyledTextWrapper = styled.p`
  color: var(--text-icon-black-secondary-78, rgba(26, 32, 38, 0.78));
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 4700;
  line-height: 20px; /* 142.857% */

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

interface StartPageIntroProps {
  company?: string;
  expiresAt?: any;
}

const StartPageIntro: React.FC<StartPageIntroProps> = ({
  expiresAt,
  company = 'Checkr',
}) => {
  const prefix = 'startPage.intro';
  const formattedDate = useMemo(
    () =>
      expiresAt &&
      new Date(expiresAt).toLocaleDateString('en-US', {
        timeZone: 'UTC',
        month: 'long',
        day: '2-digit',
        year: 'numeric',
      }),
    [expiresAt],
  );
  const introText = expiresAt ? (
    <T
      value={`${prefix}.paragraphOne`}
      company={company}
      formattedDate={formattedDate}
      dangerousHTML
    />
  ) : (
    <T value={`${prefix}.paragraphOneAlt`} company={company} dangerousHTML />
  );

  return (
    <>
      <StyledTitleWrapper data-testid={WELCOME_PAGE.getStarted}>
        <T value={`${prefix}.title`} dangerousHTML />
      </StyledTitleWrapper>
      <StyledTextWrapper data-testid={WELCOME_PAGE.accountMoreInformation}>
        {introText}
      </StyledTextWrapper>
    </>
  );
};

export default StartPageIntro;
