/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../actions';

/**
 * @name Modal
 * @function
 * @memberOf Modal.js
 * @description Global modal window container.
 * @returns {element} - The React element
 */
export class ModalComponent extends Component {
  static propTypes = {
    open: PropTypes.bool,
    childComponent: PropTypes.elementType,
    childComponentProps: PropTypes.object,
    closeModal: PropTypes.func,
  };

  renderChildComponent() {
    const { childComponent, childComponentProps, closeModal } = this.props;
    const Component = childComponent;
    const props = Object.assign({}, childComponentProps, { closeModal });
    return <Component {...props} />;
  }

  render() {
    const { open, closeModal } = this.props;
    if (!open) {
      return null;
    }
    return (
      <div className='modal-container'>
        <div
          className='modal-overlay'
          role='button'
          tabIndex={0}
          onClick={closeModal}
        />
        <div className='modal-content'>{this.renderChildComponent()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { open, childComponent, childComponentProps } = state.modal;

  return {
    ...props,
    open,
    childComponent,
    childComponentProps,
  };
};

export default connect(mapStateToProps, { closeModal })(ModalComponent);
