import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import Panel from './Panel';

const SubmitErrorPage = ({ error, accountName }) => {
  return (
    <div className='page'>
      <Panel
        i18n
        title={`components.ApplyForm.sumbitErrors.${error}.title`}
        className='warning'
        icon='fa-exclamation-triangle'
      >
        <div>
          <T
            value={`components.ApplyForm.sumbitErrors.${error}.message`}
            dangerousHTML
            accountName={accountName}
          />
        </div>
      </Panel>
    </div>
  );
};

SubmitErrorPage.propTypes = {
  accountName: PropTypes.string,
  error: PropTypes.string,
};

SubmitErrorPage.defaultProps = {
  accountName: '',
  error: '',
};

export default SubmitErrorPage;
