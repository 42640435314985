import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next'; // imported with @international/mastodon-i18n
import SectionContainer from 'containers/SectionContainer';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import WELCOME_PAGE from 'identifiers/identifiers';
import Accordion from './Accordion';

const StyledInfoTitle = styled.h3`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const StyledTitleWrapper = styled.div.attrs(props => ({
  id: props.id,
}))`
  margin-left: 8px;
  color: var(--slate-900, #14181d);
  font-family: Helvetica Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const StyledInfoText = styled.p`
  color: var(--cool-black, #16161d);
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 24px;
`;

const StyledLink = styled.a`
  color: var(--ui-link-hover-navy-700, #04417f);
  text-align: center;
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  padding-top: 16px;
`;

const getRequirementDetails = (
  requirement: string,
  prefix: string,
  t: (key: string) => string,
  additionalOptions?: any,
) => {
  let requiredDocs =
    t(`${prefix}.requirements`).split(',').length > 1
      ? t(`${prefix}.requirements`).split(',')
      : false;
  let text = `${prefix}.text`;

  if (additionalOptions) {
    switch (requirement) {
      case 'occupational_health_screening': {
        const { enabledExamCategories } = additionalOptions;
        const waivableExams =
          additionalOptions.waivableExams?.length > 0 || false;
        const serviceNames = enabledExamCategories?.flatMap((assessment: any) =>
          assessment.services?.map((service: any) => service.name),
        );
        requiredDocs =
          serviceNames?.map((option: string) =>
            t(`${prefix}.requirements.${option}`),
          ) || [];

        if (waivableExams) {
          text = `${prefix}.textWaivable`;
        }
        break;
      }
      case 'drug_test': {
        if (additionalOptions.requireClinicSelection) {
          text = `${prefix}.clinicSelectionText`;
        }
        break;
      }
    }
  }

  return { requiredDocs, text };
};

interface WhatYoullNeedProps {
  requirements: string[];
  customer: string;
  additionalOptions?: any;
}

const WhatYoullNeed: React.FC<WhatYoullNeedProps> = ({
  requirements,
  customer,
  additionalOptions,
}) => {
  const { t } = useTranslation();
  const whatYouNeedPrefix = 'startPage.whatYouNeed';

  const getPrefix = (requirement: string) =>
    `${whatYouNeedPrefix}.requirements.${requirement}`;
  const isHeaderAvailable = (header: string) =>
    !header.endsWith('.requirementsHeader');

  return (
    <SectionContainer>
      <StyledInfoTitle>
        <M.Icon icon='CertificateCheck' size={24} />
        <StyledTitleWrapper
          data-testid={WELCOME_PAGE.gatherInformation}
          id='startHeader'
        >
          <T value={`${whatYouNeedPrefix}.title`} />
        </StyledTitleWrapper>
      </StyledInfoTitle>
      <StyledInfoText>
        <T value={`${whatYouNeedPrefix}.text`} customer={customer} />
      </StyledInfoText>

      <>
        {requirements.map((requirement: string, index: number) => {
          const prefix: string = getPrefix(requirement);
          const requirementsHeader = t(`${prefix}.requirementsHeader`);
          const useRequirementsHeader: any = isHeaderAvailable(
            requirementsHeader,
          ) ? (
            requirementsHeader
          ) : (
            <T value={`${whatYouNeedPrefix}.acceptedDocuments`} />
          );

          const { requiredDocs, text } = getRequirementDetails(
            requirement,
            prefix,
            t,
            additionalOptions,
          );

          return (
            <Accordion
              key={requirement}
              title={t(`${prefix}.title`)}
              requirementHeader={useRequirementsHeader}
              text={<T value={text} dangerousHTML />}
              requiredDocs={requiredDocs}
              open={index === 0}
            />
          );
        })}
        <StyledLink href='https://help.checkr.com/hc/en-us/articles/18709153129239-I-was-asked-to-upload-an-image-of-my-documentation-What-are-the-image-guidelines-and-what-do-I-do-if-I-don-t-have-the-requested-documentation'>
          I don't have something on the list
        </StyledLink>
      </>
    </SectionContainer>
  );
};

export default WhatYoullNeed;
