import React, { ReactNode } from 'react';
import BaseLayout from 'layouts/BaseLayout';
import WhatYoullNeed from 'components/WhatYoullNeed';
import StartPageIntro from 'components/StartPageIntro';
import Steps from 'components/Steps';
import FooterFixed from 'components/FooterFixed';

interface StartPageProps {
  accountName: string;
  expiresAt?: any;
  footer: ReactNode;
  header: ReactNode;
  isTest?: boolean;
  navigation: any;
  page: any;
  pageIdx?: number;
  pages: any[];
  requirements: string[];
  additionalOptions?: any;
  showSteps?: boolean;
}

const StartPage: React.FC<StartPageProps> = ({
  accountName,
  expiresAt,
  footer,
  header,
  isTest = false,
  navigation,
  page,
  pageIdx = 0,
  pages,
  requirements,
  additionalOptions,
  showSteps = true,
}) => {
  return (
    <BaseLayout
      accountName={accountName}
      footer={footer}
      header={header}
      intro={<StartPageIntro company={accountName} expiresAt={expiresAt} />}
      isTest={isTest}
      page={page}
      steps={
        showSteps && (
          <Steps steps={pages.map(step => step.name)} currentStep={pageIdx} />
        )
      }
    >
      <WhatYoullNeed
        requirements={requirements}
        customer={accountName}
        additionalOptions={additionalOptions}
      />
      <FooterFixed navigation={navigation} />
    </BaseLayout>
  );
};

export default StartPage;
