import React from 'react';
import PropTypes from 'prop-types';
import Form from './Form';
import { scrollToFirstError } from '../utils/form';

const NavigationForm = ({ navigation, children, style = {} }) => (
  <div className='page' style={style}>
    <Form
      onSubmit={navigation.handleSubmit}
      onSubmitFail={(...args) => {
        scrollToFirstError(...args);

        if (typeof navigation.handleSubmitFail === 'function') {
          navigation.handleSubmitFail();
        }
      }}
    >
      {children}
    </Form>
  </div>
);

NavigationForm.propTypes = {
  navigation: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

NavigationForm.defaultProps = {
  style: {},
};

export default NavigationForm;
