import React from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import NavigationForm from './NavigationForm';

const StyleFooter = styled.footer`
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px 16px 12px 16px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  border-top: 1px solid var(--ui-border-primary-grey-200, #e1e6eb);
  background: var(--ui-bg-primary-grey-0, #fff);
  box-shadow: 0px -12px 32px -20px rgba(135, 147, 161, 0.25),
    0px -4px 8px -8px rgba(135, 147, 161, 0.5);
`;

const navStyle = {
  width: '100%',
};

interface NavProps {
  navigation: any;
}

const NavigationSection: React.FC<NavProps> = ({ navigation }) => (
  <NavigationForm navigation={navigation} style={navStyle}>
    {navigation.showNav && <Navigation {...navigation} />}
  </NavigationForm>
);

const FooterFixed: React.FC<NavProps> = ({ navigation }) => (
  <StyleFooter data-testid='fixed-footer'>
    <NavigationSection navigation={navigation} />
  </StyleFooter>
);

export default FooterFixed;
