// eslint-disable-next-line import/prefer-default-export
export const scrollToFirstError = errors => {
  const firstError = Object.keys(errors)[0];
  const inputWithError = document.querySelector(`[name="${firstError}"]`);
  if (inputWithError) {
    const position =
      inputWithError.getBoundingClientRect().top +
      document.documentElement.scrollTop;
    const offset = 100;
    window.scrollTo({ top: position - offset, behavior: 'smooth' });
  }
};
